import PropTypes from 'prop-types';
import React from 'react';

import './Files.css';

const fileShape = {
  isDir: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

class File extends React.Component {
  static propTypes = fileShape;

  render() {
    const { isDir, path, filename, navigateTo } = this.props;

    const classes = ['File'];
    if (isDir) {
      classes.push('File--dir');
    }

    return (
      <div className="col-md-4">
        <div className={classes.join(' ')}>
          {isDir
            ? <a href={path} onClick={(e) => navigateTo(e, path)}>{filename}/</a>
            : <a href={path}>{filename}</a>
          }
        </div>
      </div>
    );
  }
}

export default class Files extends React.Component {
  static propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape(fileShape)),
    navigateTo: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="Files row">
        {this.props.files.map((file, idx) =>
          <File key={idx + file.path + '/' + file.filename} navigateTo={this.props.navigateTo} {...file} />
        )}
      </div>
    );
  }
}
