const API_SERVER_DEVELOPMENT = 'http://frost-files.localhost:8888/api';

export default class APIClient {
  constructor(baseURL) {
    const { host, protocol } = window.location;
    this.baseURL = process.env.NODE_ENV === 'production' ? `${protocol}//${host}/api` : API_SERVER_DEVELOPMENT;
  }

  fetch(path) {
    const url = this.baseURL + '/files' + path;

    return fetch(url)
      .then(res => {
        if (!res.ok) {
          throw new Error(`Failed to fetch ${path}: ${res.status} ${res.statusText}`);
        }

        return res.json();
      });
  }

  search(terms) {
    const url = this.baseURL + '/search?terms=' + encodeURIComponent(terms);

    return fetch(url)
      .then(res => {
        if (!res.ok) {
          throw new Error(`Failed to search: ${res.status} ${res.statusText}`);
        }

        return res.json();
      });
  }
}
