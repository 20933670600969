import PropTypes from 'prop-types';
import React from 'react';

import './Search.css';

export default class Search extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    terms: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  onSearch(e) {
    e.preventDefault();
    this.props.onSearch();
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onClear(e) {
    this.props.onChange('');
    this.props.onSearch();
  }

  render() {
    return (
      <form className="Search form-row justify-content-end mb-3" onSubmit={this.onSearch}>
        <div className="col-sm-6 my-1">
          <input type="search"
            className="form-control"
            placeholder="Search"
            value={this.props.terms}
            onChange={this.onChange} />
        </div>
        <div className="col-auto my-1">
          <button className="btn btn-primary" type="submit">Search</button>
          <button className="btn btn-secondary mx-1" onClick={this.onClear}>Clear</button>
        </div>
      </form>
    );
  }
}
