import React from 'react';

import './Banner.css';

class Banner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Banner">
        There is now an unofficial <a href="https://frost.drarok.com/wiki/" target="_blank">Frost* Wiki</a>!
      </div>
    );
  }
}

export default Banner;
