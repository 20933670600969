import React from 'react';

import './Footer.css';

export default class Footer extends React.Component {
  render() {
    const year = (new Date()).getFullYear();

    return (
      <footer className="Footer">
        <div className="container">
          <p className="text-muted">&copy; 2017 &ndash; {year} Mat Gadd</p>
        </div>
      </footer>
    );
  }
}
