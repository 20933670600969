import React from 'react';

import APIClient from './APIClient';
import Banner from './Banner';
import Breadcrumbs from './Breadcrumbs';
import Files from './Files';
import Footer from './Footer';
import Loader from './Loader';
import Search from './Search';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.api = new APIClient();

    this.state = {
      error: null,
      crumbs: [],
      files: null,
      search: '',
      searching: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.navigateTo = this.navigateTo.bind(this);
    this.onPopState = this.onPopState.bind(this);
  }

  componentDidMount() {
    if (window.location.search.length > 0) {
      const matches = window.location.search.match(/terms=([^=]+)/);
      this.onSearch(matches[1]);
    } else {
      this.fetchPath(window.location.pathname, false);
    }

    window.addEventListener('popstate', this.onPopState, false);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopState, false);
  }

  onPopState(e) {
    if (e.state === null) {
      this.componentDidMount();
    } else {
      this.setState(e.state);
    }
  }

  navigateTo(e, path) {
    e.preventDefault();
    this.fetchPath(path);
  }

  fetchPath(path, updateHistory) {
    this.api.fetch(path)
      .then(json => {
        const newState = {
          path,
          search: '',
          searching: false,
          ...json,
        };

        this.setState(newState);

        const { host, protocol } = window.location;
        const url = `${protocol}//${host}${path}`;

        if (updateHistory === false) {
          window.history.replaceState(newState, '', url);
        } else {
          window.history.pushState(newState, '', url);
        }
      })
      .catch(err => {
        console.error(err.stack);
        this.setState({ error: err });
      });
  }

  onChange(terms) {
    this.setState({ search: terms });
  }

  onSearch(terms) {
    // Temporary hack to ensure we have the latest state.
    if (terms === undefined) {
      return this.setState(state => {
        setTimeout(() => this.onSearch(state.search || ''), 0);
      });
    }

    if (terms.trim().length === 0) {
      this.setState({
        searching: false,
        search: '',
      });
      this.fetchPath('/');
      return;
    }

    const { host, protocol } = window.location;
    const url = `${protocol}//${host}/?terms=${encodeURIComponent(terms)}`;
    const newState = {
      crumbs: [],
      files: null,
      search: terms,
      searching: true,
    };

    this.setState(newState);
    window.history.pushState(newState, '', url);

    this.api.search(terms)
      .then(json => {
        if (!json.files) {
          return this.setState({
            searching: false,
            search: terms,
            error: new Error('Failed to perform search.'),
          });
        }

        this.setState({
          searching: false,
          search: terms,
          files: json.files,
        });
      })
      .catch(error => this.setState({ error }));
  }

  render() {
    return (
      <div className="App">
        <div className="container">
          <Banner />

          <header>
            <Breadcrumbs crumbs={this.state.crumbs} navigateTo={this.navigateTo} />
          </header>

          <Search
            onChange={this.onChange}
            onSearch={this.onSearch}
            terms={this.state.search} />

          <main>
            {this.state.error &&
              <div className="alert alert-danger">{this.state.error.message}</div>
            }
            {this.state.searching &&
              <Loader />
            }
            {this.state.files !== null &&
              <Files files={this.state.files} navigateTo={this.navigateTo} />
            }
            {this.state.searching === false &&
              this.state.files !== null &&
              this.state.files.length === 0 &&
              <div className="alert alert-secondary" role="alert">No files found</div>
            }
          </main>
        </div>

        <Footer />
      </div>
    );
  }
}

export default App;
