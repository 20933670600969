import PropTypes from 'prop-types';
import React from 'react';

class Item extends React.Component {
  static propTypes = {
    isLast: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    navigateTo: PropTypes.func.isRequired,
  };

  render() {
    if (this.props.isLast) {
      return <li className="breadcrumb-item active" aria-current="page">{this.props.title}</li>
    } else {
      return (
        <li className="breadcrumb-item">
          <a
            href={this.props.path}
            onClick={(e) => this.props.navigateTo(e, this.props.path)}>{this.props.title}</a>
        </li>
      );
    }
  }
}

export default class Breadcrumbs extends React.Component {
  static propTypes = {
    crumbs: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })),
    navigateTo: PropTypes.func.isRequired,
  };

  render() {
    const crumbs = this.props.crumbs.slice();
    crumbs.unshift({
      path: '/',
      title: 'Frost',
    });

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {crumbs.map((crumb, idx, all) =>
            <Item key={idx} isLast={idx === all.length - 1} navigateTo={this.props.navigateTo} {...crumb} />
          )}
        </ol>
      </nav>
    );
  }
}
